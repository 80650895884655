@import 'theme';
@import 'variables';
@import 'gridstack/dist/gridstack.min.css';

body, select, button, input {
	font-family: open-sans, sans-serif !important;
	background-color: #ffffff;
}

h1,h2,h3,h4 {
	font-family: open-sans, sans-serif !important;
	color: $color-fg;
}

sup.required {
	color: $konfoo-red;
}

.grid-stack-item {
	//font-family: monospace;
	padding: 1px !important;
}

.grid-stack-item-content {
	text-align: left;
	padding-top: 6px;
	background-color: transparent;
	overflow-x: hidden !important;
	overflow-y: hidden !important;

	//border: 1px dashed #3d3d3d; // DEBUG
}

//.mat-step-label {
//	color: $color-fg !important;
//	font-family: open-sans, sans-serif !important;
//}

//mat-panel-title {
//	color: $color-fg !important;
//	font-family: open-sans, sans-serif !important;
//}

//mat-option {
//	color: $color-fg !important;
//	font-family: open-sans, sans-serif !important;
//}

//.mat-expansion-panel-body {
//	color: $color-fg !important;
//	font-family: open-sans, sans-serif !important;
//}

//select {
//	border: 1px solid $color-select-border;
//	border-radius: 6px;
//
//	cursor: pointer;
//	padding: 3px 23px;
//	outline: 0;
//	background: $color-select-bg;
//	color: $color-select-fg;
//}

//button {
//	border: 0;
//	border-radius: 2px;
//	color: $color-btn-fg;
//}

.btn-run {
	background-color: $color-btn-primary;
	height: 24px;
	padding-left: 16px;
	padding-right: 16px;
	font-weight: normal;
	color: $color-btn-fg;

	&:hover {
		background-color: darken($color-btn-primary, 5%);
	}

	&:disabled {
		background-color: $color-btn-primary-disabled;
	}
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.hidden {
	display: none !important;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.panel {
	// border: 1px solid tomato;

	width: 500px;
	height: calc(100vh - 100px);

	margin-left: 2px;
	margin-right: 8px;
}

.panel-closed {
	margin-top: 12px;
	margin-right: 8px;
	background-color: darken($color-bg, 2%);

	height: calc(100vh - 100px);

	writing-mode: vertical-rl;
	padding-top: 32px;
}

.panel-header {
	font-size: 1em !important;
	cursor: pointer;

	&:hover {
		color: lighten($color-fg, 15%);
	}
}

.logo {
	background-image: url("./assets/konfoo-red.svg");
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	width: 120px;
	height: 64px;
}
